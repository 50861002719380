.row-flex {
  display: flex;
  flex-direction: column;
  flex-direction: column-reverse;
}

.feature-section {
  margin-bottom: 60px;
  margin-top: 60px;
}

.feature-desc {
  padding: 10px var(--padding);
  margin-bottom: 20px;
}

.reverse > .illustration {
  align-items: center;
}

.row-flex > .illustration {
  display: flex;
  justify-content: center;
}

@media (min-width: 700px) {
  .row-flex {
    display: flex;
    flex-direction: row;
  }

  .row-flex > div {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .reverse {
    flex-direction: row-reverse;
  }

  .feature-section {
    margin: 0 auto;
    margin-bottom: 150px;
    max-width: 75%;
    gap: 120px;
  }

  .feature-desc {
    margin-bottom: 0;
  }
}
