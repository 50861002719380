.faq {
  margin: var(--padding);
}

details {
  transition: all 1s ease;
  overflow: hidden;
}

details > summary {
  padding: 24px 0;
  border-bottom: 1px solid #171a38;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  justify-content: space-between;
}

details[open] > summary > svg {
  transform: rotate(180deg);
}

details[open] > summary::after {
  content: url(../../icons/arrow-down.svg);
  transform: rotate(180deg);
}

details > summary::after {
  content: url(../../icons/arrow-down.svg);
  transition: all 0.3s ease-in-out;
}

details > summary > h4 {
  width: 100%;
}

details > summary > span {
  width: 24px;
  margin-right: 24px;
}

details > div {
  padding: 16px 48px;
}

details[open] summary ~ * {
  animation: fadeDown 0.25s ease-in-out;
}

details:not([open]) summary ~ * {
  animation: fadeUp 0.25s ease-in-out;
}

@keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(-1.25em);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-1.25em);
  }
}

@media (min-width: 700px) {
  .faq {
    max-width: 800px;
    margin: 0 auto 100px;
  }
}
