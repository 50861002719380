.estimation-section {
  padding: var(--padding);
}

.form {
  background: rgba(17, 27, 79, 0.2);
  border-radius: 16px;
  padding: 32px 24px;
  color: rgba(255, 255, 255, 0.8);
  margin: 40px 0 80px 0;
}

.form > div:not(:last-child) {
  margin-bottom: 30px;
}

.form-input {
  background: rgba(255, 255, 255, 0.04);
  border-radius: 8px;
  padding: 10px 20px;
  max-width: 490px;
}

label {
  font-size: 12px;
}

.label {
  text-transform: uppercase;
}

.charges-label {
  display: inline-block;
  margin-top: 24px;
}

.outside-label {
  margin-bottom: 10px;
}

.amount-input {
  display: flex;
  font-size: 24px;
  font-weight: 700;
}

.amount-input > input {
  background-color: transparent;
  border: 0;
  outline: 0;
  color: #fff;
  display: block;
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  margin-left: 5px;
}

.amount {
  font-weight: 600;
  font-size: 20px;
  color: rgba(255, 255, 255, 1);
}

@media (min-width: 700px) {
  .estimation-section {
    display: flex;
    align-items: center;
    width: 70%;
    margin: 0 auto;
  }

  .estimation-content {
    width: 50%;
    padding-right: 80px;
    margin-top: -100px;
  }

  .form {
    width: 50%;
  }

  .amount-charges {
    display: flex;
    justify-content: space-between;
  }

  .charges-label {
    margin-top: 0;
  }
}
