:root {
  --primary: #0029ff;
  --padding: 20px;
  --font: 'Euclid Circular A Regular', sans-serif;
  --font-bold: 'Euclid Circular A Bold', sans-serif;
  --font-light: 'Euclid Circular A Light', sans-serif;
  --font-medium: 'Euclid Circular A Medium', sans-serif;
  --font-semi-bold: 'Euclid Circular A SemiBold', sans-serif;
  --page-margin: 20px;
}

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: var(--font);
  line-height: 1.5;
}

body {
  background-color: #000624;
  color: #fff;
  font-size: 16px;
  overflow-x: hidden;
}

a {
  color: #fff;
  cursor: pointer;
  text-decoration: underline;
}

.btn,
button {
  background-color: var(--primary);
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 16px 0;
  max-width: 327px;
  width: 100%;
  border-radius: 8px;
  font-family: var(--font-medium);
  font-size: 16px;
  text-decoration: none;
  display: inline-block;
  text-align: center;
}

button:disabled {
  opacity: 0.6;
}

p {
  color: #dddce0;
  display: block;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-family: var(--font);
}

h1 {
  font-size: 45px;
  font-family: var(--font-semi-bold);
}

h3 {
  font-size: 30px;
  font-family: var(--font-semi-bold);
  margin: 24px 0;
}

.hide {
  display: none;
}

.invisible {
  visibility: hidden;
}

@media (min-width: 700px) {
  :root {
    --page-margin: 20%;
  }

  h1 {
    font-size: 64px;
  }
}
