@font-face {
  font-family: 'Euclid Circular A Bold';
  src: url('/public/fonts/EuclidCircularA-Bold.eot');
  src: local('Euclid Circular A Bold'), local('EuclidCircularA-Bold'),
    url('/public/fonts/EuclidCircularA-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('/public/fonts/EuclidCircularA-Bold.woff2') format('woff2'),
    url('/public/fonts/EuclidCircularA-Bold.woff') format('woff'),
    url('/public/fonts/EuclidCircularA-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Euclid Circular A Light';
  src: url('/public/fonts/EuclidCircularA-Light.eot');
  src: local('Euclid Circular A Light'), local('EuclidCircularA-Light'),
    url('/public/fonts/EuclidCircularA-Light.eot?#iefix')
      format('embedded-opentype'),
    url('/public/fonts/EuclidCircularA-Light.woff2') format('woff2'),
    url('/public/fonts/EuclidCircularA-Light.woff') format('woff'),
    url('/public/fonts/EuclidCircularA-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Euclid Circular A Regular';
  src: url('/public/fonts/EuclidCircularA-Regular.eot');
  src: local('Euclid Circular A Regular'), local('EuclidCircularA-Regular'),
    url('/public/fonts/EuclidCircularA-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('/public/fonts/EuclidCircularA-Regular.woff2') format('woff2'),
    url('/public/fonts/EuclidCircularA-Regular.woff') format('woff'),
    url('/public/fonts/EuclidCircularA-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Euclid Circular A SemiBold';
  src: url('/public/fonts/EuclidCircularA-SemiBold.eot');
  src: local('Euclid Circular A SemiBold'), local('EuclidCircularA-SemiBold'),
    url('/public/fonts/EuclidCircularA-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('/public/fonts/EuclidCircularA-SemiBold.woff2') format('woff2'),
    url('/public/fonts/EuclidCircularA-SemiBold.woff') format('woff'),
    url('/public/fonts/EuclidCircularA-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Euclid Circular A Medium';
  src: url('/public/fonts/EuclidCircularA-Medium.eot');
  src: local('Euclid Circular A Medium'), local('EuclidCircularA-Medium'),
    url('/public/fonts/EuclidCircularA-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('/public/fonts/EuclidCircularA-Medium.woff2') format('woff2'),
    url('/public/fonts/EuclidCircularA-Medium.woff') format('woff'),
    url('/public/fonts/EuclidCircularA-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
