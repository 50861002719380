.container {
  position: relative;
  max-width: 236px;
}

.select {
  position: relative;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: none;
  text-transform: capitalize;
  color: #fff;
  background: rgba(255, 255, 255, 0.04);
  text-align: left;
  padding: 0 24px;
  font-size: 16px;
  font-family: var(--font);
  cursor: pointer;
  display: flex;
  align-items: center;
}

.select > span {
  margin-left: 16px;
}

.select > span,
.select > svg {
  pointer-events: none;
}

.select::after {
  content: '';
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  width: 6px;
  height: 6px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.select:hover {
  background: rgba(255, 255, 255, 0.04);
}

.select.active {
  background: rgba(255, 255, 255, 0.04);
  border-radius: 8px;
}

.options {
  position: absolute;
  top: 76px;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  overflow: hidden;
  display: none;
  padding: 24px;
  list-style: none;
}

.options.active {
  display: block;
}

.options .item {
  color: #000624;
  text-transform: capitalize;
  width: 100%;
  display: flex;
  padding-bottom: 16px;
  cursor: pointer;
}

.options .item.active {
  background: rgba(255, 255, 255, 0.04);
}

.options .item > span {
  margin-left: 16px;
}

.options .item:last-child {
  padding-bottom: 0;
}
