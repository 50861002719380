.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.legal-links {
  display: flex;
  gap: 24px;
  margin: 32px 0;
}

.legal-links li {
  list-style: none;
  text-decoration: none;
}

.footer > .social-links {
  margin-bottom: 50px;
}

@media (min-width: 700px) {
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: var(--padding) auto;
    max-width: 75%;
  }

  .footer > .social-links {
    margin-bottom: 0;
  }
}
