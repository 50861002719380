@font-face {
  font-family: "Euclid Circular A Bold";
  src: url("EuclidCircularA-Bold.8bab8570.eot");
  src: local("Euclid Circular A Bold"), local("EuclidCircularA-Bold"), url("EuclidCircularA-Bold.8bab8570.eot#iefix") format("embedded-opentype"), url("EuclidCircularA-Bold.30d43d46.woff2") format("woff2"), url("EuclidCircularA-Bold.b160fe01.woff") format("woff"), url("EuclidCircularA-Bold.c1bb95aa.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Euclid Circular A Light";
  src: url("EuclidCircularA-Light.2aa6aa0f.eot");
  src: local("Euclid Circular A Light"), local("EuclidCircularA-Light"), url("EuclidCircularA-Light.2aa6aa0f.eot#iefix") format("embedded-opentype"), url("EuclidCircularA-Light.a91dd36c.woff2") format("woff2"), url("EuclidCircularA-Light.c2b314b2.woff") format("woff"), url("EuclidCircularA-Light.afbecad8.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Euclid Circular A Regular";
  src: url("EuclidCircularA-Regular.52847ba5.eot");
  src: local("Euclid Circular A Regular"), local("EuclidCircularA-Regular"), url("EuclidCircularA-Regular.52847ba5.eot#iefix") format("embedded-opentype"), url("EuclidCircularA-Regular.2762d323.woff2") format("woff2"), url("EuclidCircularA-Regular.2fd86fbd.woff") format("woff"), url("EuclidCircularA-Regular.0d0b7b42.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Euclid Circular A SemiBold";
  src: url("EuclidCircularA-SemiBold.b1768348.eot");
  src: local("Euclid Circular A SemiBold"), local("EuclidCircularA-SemiBold"), url("EuclidCircularA-SemiBold.b1768348.eot#iefix") format("embedded-opentype"), url("EuclidCircularA-SemiBold.195defdc.woff2") format("woff2"), url("EuclidCircularA-SemiBold.2ea26bb1.woff") format("woff"), url("EuclidCircularA-SemiBold.60d15d3e.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Euclid Circular A Medium";
  src: url("EuclidCircularA-Medium.a7ca0626.eot");
  src: local("Euclid Circular A Medium"), local("EuclidCircularA-Medium"), url("EuclidCircularA-Medium.a7ca0626.eot#iefix") format("embedded-opentype"), url("EuclidCircularA-Medium.6747f6fa.woff2") format("woff2"), url("EuclidCircularA-Medium.a9972449.woff") format("woff"), url("EuclidCircularA-Medium.f28a13c5.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
:root {
  --primary: #0029ff;
  --padding: 20px;
  --font: "Euclid Circular A Regular", sans-serif;
  --font-bold: "Euclid Circular A Bold", sans-serif;
  --font-light: "Euclid Circular A Light", sans-serif;
  --font-medium: "Euclid Circular A Medium", sans-serif;
  --font-semi-bold: "Euclid Circular A SemiBold", sans-serif;
  --page-margin: 20px;
}
html {
  scroll-behavior: smooth;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: var(--font);
  line-height: 1.5;
}
body {
  background-color: #000624;
  color: #fff;
  font-size: 16px;
  overflow-x: hidden;
}
a {
  color: #fff;
  cursor: pointer;
  text-decoration: underline;
}
.btn,
button {
  background-color: var(--primary);
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 16px 0;
  max-width: 327px;
  width: 100%;
  border-radius: 8px;
  font-family: var(--font-medium);
  font-size: 16px;
  text-decoration: none;
  display: inline-block;
  text-align: center;
}
button:disabled {
  opacity: 0.6;
}
p {
  color: #dddce0;
  display: block;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-family: var(--font);
}
h1 {
  font-size: 45px;
  font-family: var(--font-semi-bold);
}
h3 {
  font-size: 30px;
  font-family: var(--font-semi-bold);
  margin: 24px 0;
}
.hide {
  display: none;
}
.invisible {
  visibility: hidden;
}
@media (min-width: 700px) {
  :root {
    --page-margin: 20%;
  }

  h1 {
    font-size: 64px;
  }
}
.row-flex {
  display: flex;
  flex-direction: column;
  flex-direction: column-reverse;
}
.feature-section {
  margin-bottom: 60px;
  margin-top: 60px;
}
.feature-desc {
  padding: 10px var(--padding);
  margin-bottom: 20px;
}
.reverse > .illustration {
  align-items: center;
}
.row-flex > .illustration {
  display: flex;
  justify-content: center;
}
@media (min-width: 700px) {
  .row-flex {
    display: flex;
    flex-direction: row;
  }

  .row-flex > div {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .reverse {
    flex-direction: row-reverse;
  }

  .feature-section {
    margin: 0 auto;
    margin-bottom: 150px;
    max-width: 75%;
    gap: 120px;
  }

  .feature-desc {
    margin-bottom: 0;
  }
}
.estimation-section {
  padding: var(--padding);
}
.form {
  background: rgba(17, 27, 79, 0.2);
  border-radius: 16px;
  padding: 32px 24px;
  color: rgba(255, 255, 255, 0.8);
  margin: 40px 0 80px 0;
}
.form > div:not(:last-child) {
  margin-bottom: 30px;
}
.form-input {
  background: rgba(255, 255, 255, 0.04);
  border-radius: 8px;
  padding: 10px 20px;
  max-width: 490px;
}
label {
  font-size: 12px;
}
.label {
  text-transform: uppercase;
}
.charges-label {
  display: inline-block;
  margin-top: 24px;
}
.outside-label {
  margin-bottom: 10px;
}
.amount-input {
  display: flex;
  font-size: 24px;
  font-weight: 700;
}
.amount-input > input {
  background-color: transparent;
  border: 0;
  outline: 0;
  color: #fff;
  display: block;
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  margin-left: 5px;
}
.amount {
  font-weight: 600;
  font-size: 20px;
  color: white;
}
@media (min-width: 700px) {
  .estimation-section {
    display: flex;
    align-items: center;
    width: 70%;
    margin: 0 auto;
  }

  .estimation-content {
    width: 50%;
    padding-right: 80px;
    margin-top: -100px;
  }

  .form {
    width: 50%;
  }

  .amount-charges {
    display: flex;
    justify-content: space-between;
  }

  .charges-label {
    margin-top: 0;
  }
}
.faq {
  margin: var(--padding);
}
details {
  transition: all 1s ease;
  overflow: hidden;
}
details > summary {
  padding: 24px 0;
  border-bottom: 1px solid #171a38;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  justify-content: space-between;
}
details[open] > summary > svg {
  transform: rotate(180deg);
}
details[open] > summary::after {
  content: url(arrow-down.e678069f.svg);
  transform: rotate(180deg);
}
details > summary::after {
  content: url(arrow-down.e678069f.svg);
  transition: all 0.3s ease-in-out;
}
details > summary > h4 {
  width: 100%;
}
details > summary > span {
  width: 24px;
  margin-right: 24px;
}
details > div {
  padding: 16px 48px;
}
details[open] summary ~ * {
  animation: fadeDown 0.25s ease-in-out;
}
details:not([open]) summary ~ * {
  animation: fadeUp 0.25s ease-in-out;
}
@keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(-1.25em);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-1.25em);
  }
}
@media (min-width: 700px) {
  .faq {
    max-width: 800px;
    margin: 0 auto 100px;
  }
}
.access {
  background: rgba(255, 255, 255, 0.05);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
  border-radius: 48px 48px 0px 0px;
  padding: 32px 24px;
  margin: 100px var(--padding) 80px;
  position: relative;
}
.access-content > p {
  margin: 20px 0;
}
.access-content > h3 {
  margin: 0;
}
.access-button {
  width: 100%;
}
.phone-container {
  display: none;
}
@media (min-width: 700px) {
  .access {
    padding: 80px 120px 120px;
    margin: 150px auto 80px;
    position: relative;
    max-width: 75%;
  }

  .access-content {
    width: 50%;
  }

  .access-content > p {
    margin: 20px 0;
  }

  .access-button {
    width: 180px;
  }

  .phone-container {
    position: absolute;
    top: -120px;
    right: 65px;
    display: block;
  }

  .phone-container {
    transform: translateY(12%);
  }
}
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.legal-links {
  display: flex;
  gap: 24px;
  margin: 32px 0;
}
.legal-links li {
  list-style: none;
  text-decoration: none;
}
.footer > .social-links {
  margin-bottom: 50px;
}
@media (min-width: 700px) {
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: var(--padding) auto;
    max-width: 75%;
  }

  .footer > .social-links {
    margin-bottom: 0;
  }
}
.container {
  position: relative;
  max-width: 236px;
}
.select {
  position: relative;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: none;
  text-transform: capitalize;
  color: #fff;
  background: rgba(255, 255, 255, 0.04);
  text-align: left;
  padding: 0 24px;
  font-size: 16px;
  font-family: var(--font);
  cursor: pointer;
  display: flex;
  align-items: center;
}
.select > span {
  margin-left: 16px;
}
.select > span,
.select > svg {
  pointer-events: none;
}
.select::after {
  content: "";
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  width: 6px;
  height: 6px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.select:hover {
  background: rgba(255, 255, 255, 0.04);
}
.select.active {
  background: rgba(255, 255, 255, 0.04);
  border-radius: 8px;
}
.options {
  position: absolute;
  top: 76px;
  left: 0;
  width: 100%;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  display: none;
  padding: 24px;
  list-style: none;
}
.options.active {
  display: block;
}
.options .item {
  color: #000624;
  text-transform: capitalize;
  width: 100%;
  display: flex;
  padding-bottom: 16px;
  cursor: pointer;
}
.options .item.active {
  background: rgba(255, 255, 255, 0.04);
}
.options .item > span {
  margin-left: 16px;
}
.options .item:last-child {
  padding-bottom: 0;
}
.page-container {
  position: relative;
  width: 100vw;
  height: 100%;
  max-width: 1600px;
  margin: 0 auto;
}
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
}
.social-links {
  list-style: none;
}
.social-links > li {
  display: inline-block;
  height: 24px;
  width: 24px;
}
.social-links > li:not(:last-of-type) {
  margin-right: 8px;
}
.social-links > li > a {
  display: inline-block;
  height: 100%;
  width: 100%;
  transition: transform 0.15s linear;
}
.social-links > li > a:hover {
  transform: scale3d(0.9, 0.9, 0.9);
}
.logo-box {
  display: inline-flex;
  align-items: center;
  position: relative;
}
.logo-box img {
  height: 32px;
  width: auto;
}
.logo-box > span {
  margin-left: 16px;
  display: inline-block;
  position: relative;
}
.top-section {
  padding: 20px var(--padding);
}
.content-box {
  text-align: center;
}
.content-box > p {
  margin-top: 20px;
  line-height: 1.75;
  max-width: 700px;
  margin: 0 auto;
  color: #dddce0;
}
.dashboard-image {
  min-height: 200px;
}
.cta-btn {
  margin: 24px 0 48px;
  width: 100%;
}
.header-image {
  width: 100%;
}
.page-form {
  margin-top: 40px;
}
.page-form > input[type=email] {
  height: 56px;
  border: 0;
  padding: 18px 20px;
  border-radius: 8px;
  width: 100%;
  margin-right: 12px;
  margin-bottom: 10px;
  font-size: 16px;
  box-sizing: border-box;
  display: inline-block;
}
.page-form > button {
  background: #0029ff;
  border-radius: 8px;
  line-height: 56px;
  height: 56px;
  width: 100%;
  border: 0;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  font-family: GT Walsheim Pro Bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-1px);
}
.page-form > button > svg {
  height: 20px;
  width: 40px;
  display: none;
}
.page-form > button:disabled > svg {
  display: inline;
}
.floating-div {
  display: inline-flex;
  align-items: flex-start;
  padding: 22px 18px;
  background: rgba(251, 251, 251, 0.06);
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  position: relative;
  margin-top: 48px;
  font-size: 12px;
}
.floating-div > svg {
  width: 15px;
  margin-right: 7px;
}
.toast {
  width: 90%;
  max-width: 480px;
  background: #ffffff;
  border-radius: 0 11.8px 11.8px 0;
  position: fixed;
  height: 60px;
  top: 24px;
  right: 24px;
  transform: translateX(200%);
  color: #000;
  display: none;
  align-items: center;
}
.toast-success {
  display: flex;
  border-left: 3px solid #15a336;
  animation: toast-enter 0.3s ease-in forwards;
}
.toast-warning {
  display: flex;
  border-left: 3px solid #e7b52b;
  animation: toast-enter 0.5s ease-in forwards;
}
.toast-warning > button > svg path {
  fill: #e7b52b;
}
.toast-warning > .circle {
  background-color: rgba(231, 181, 43, 0.1);
  display: none;
}
.toast-message {
  margin: 0 40px 0 18px;
}
.circle {
  height: 32px;
  width: 32px;
  background-color: rgba(21, 163, 54, 0.06);
  border-radius: 50%;
  z-index: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 13px;
}
.circle > svg {
  z-index: 1;
  height: 14px;
  width: 14px;
}
.toast-close {
  display: inline-block;
  background-color: transparent;
  border: none;
  position: absolute;
  right: 24px;
  height: 20px;
  width: 20px;
}
@keyframes toast-enter {
  0% {
    transform: translateX(110%);
  }
  70% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(0);
  }
}
.dialog {
  display: none;
}
.dialog.open {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.dialog-success.open {
  display: flex;
}
.dialog-success {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 500px;
  background: #000624;
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 48px;
  display: none;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.dialog-success > h3 {
  font-size: 30px;
  font-family: GT Walsheim Pro Bold;
  margin-bottom: 15px;
}
.rocket {
  font-size: 48px;
  margin-bottom: 24px;
}
.share-box {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  width: 100%;
  max-width: 220px;
  margin: 40px auto 0;
}
.share-button {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  color: #fff;
  box-shadow: 0px 10px 15px -2px rgba(0, 41, 255, 0.15);
  border-radius: 8px;
  transition: width 0.35s linear;
}
.share-button.clicked {
  width: 45%;
}
.share-social {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s linear;
}
.share-link {
  display: inline-flex;
  height: 58px;
  width: 58px;
  background-color: var(--primary);
  color: #fff;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 10px 15px -2px rgba(0, 41, 255, 0.15);
  border-radius: 8px;
}
.share-link > svg {
  width: 20px;
  height: 20px;
}
.dialog-content.open {
  display: block;
}
.dialog-content {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 90%;
  background: #000624;
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
  border-radius: 16px 16px 0 0;
  padding: 30px 24px;
}
.dialog > .back-drop {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(30, 31, 38, 0.3);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}
.dialog-content > svg {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
}
.dialog-content > header {
  display: flex;
  justify-content: space-between;
}
.dialog-content > header > div {
  display: none;
}
.dialog-content > article {
  background-color: rgba(255, 255, 255, 0.06);
  padding: 24px;
  margin-top: 24px;
  border-radius: 16px;
  overflow: scroll;
  height: 100%;
  padding-bottom: 50px;
}
article p {
  margin-top: 16px;
  font-weight: normal;
}
article > div:not(:first-of-type) {
  margin-top: 24px;
}
.dialog-content-header {
  font-size: 1.25rem;
  font-family: GT Walsheim Pro Bold;
}
article p:first-child {
  margin-top: 0;
}
.key {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  font-size: 12px;
  padding: 2px 10px;
  display: inline-block;
  margin-left: 5px;
}
::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.auth-links {
  display: none;
}
@media (min-width: 700px) {
  .nav {
    max-width: 86%;
    margin: 0 auto;
  }

  .auth-links {
    display: flex;
    list-style-type: none;
    gap: 32px;
    align-items: center;
  }

  .auth-links a {
    text-decoration: none;
  }

  .signup-btn {
    background: #0029ff;
    border-radius: 8px;
    width: 126px;
    display: inline-block;
    text-align: center;
    padding: 10px 0;
  }

  .top-section {
    max-width: 75%;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 150px;
  }

  .content-box > h1 {
    animation: animate-heading 0.3s ease-in forwards;
  }

  .content-box > p {
    font-size: 18px;
    margin: 24px auto;
  }

  .dashboard-image {
    min-height: 500px;
  }

  .cta-btn {
    width: 200px;
    margin-bottom: 80px;
  }

  .logo-box img {
    height: 50px;
    width: auto;
  }

  .dialog-success {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
    max-width: 480px;
    background: #000624;
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding: 48px;
    display: none;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .share-box {
    max-width: none;
  }

  .share-button {
    display: block;
  }

  .share-social {
    opacity: 0;
    visibility: hidden;
  }

  .share-social {
    width: 52%;
  }

  .row-flex {
    display: flex;
    flex-direction: row;
  }

  .row-flex > div {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .reverse {
    flex-direction: row-reverse;
  }
}
@keyframes animate-heading {
  from {
    color: transparent;
    filter: blur(4px);
    text-shadow: 0 0 32px rgba(0, 0, 0, 0.5);
    transform: scale3d(1.05, 1.1, 1.05);
  }
  to {
    text-shadow: none;
    color: #fff;
    transform: scale3d(1, 1, 1);
  }
}
/*# sourceMappingURL=index.0576b895.css.map */
