@import './fonts.scss';
@import './global.scss';
@import './features.scss';
@import './estimation.scss';
@import './faq.scss';
@import './access.scss';
@import './footer.scss';
@import './select.scss';

.page-container {
  position: relative;
  width: 100vw;
  height: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
}

.social-links {
  list-style: none;
}

.social-links > li {
  display: inline-block;
  height: 24px;
  width: 24px;
}

.social-links > li:not(:last-of-type) {
  margin-right: 8px;
}

.social-links > li > a {
  display: inline-block;
  height: 100%;
  width: 100%;
  transition: transform 0.15s linear;
}

.social-links > li > a:hover {
  transform: scale3d(0.9, 0.9, 0.9);
}

.logo-box {
  display: inline-flex;
  align-items: center;
  position: relative;
}

.logo-box img {
  height: 32px;
  width: auto;
}

.logo-box > span {
  margin-left: 16px;
  display: inline-block;
  position: relative;
}

.top-section {
  padding: 20px var(--padding);
}

.content-box {
  text-align: center;
}

.content-box > p {
  margin-top: 20px;
  line-height: 1.75;
  max-width: 700px;
  margin: 0 auto;
  color: #dddce0;
}

.dashboard-image {
  min-height: 200px;
}

.cta-btn {
  margin: 24px 0 48px;
  width: 100%;
}

.header-image {
  width: 100%;
}

.page-form {
  margin-top: 40px;
}

.page-form > input[type='email'] {
  height: 56px;
  border: 0;
  padding: 18px 20px;
  border-radius: 8px;
  width: 100%;
  margin-right: 12px;
  margin-bottom: 10px;
  font-size: 16px;
  box-sizing: border-box;
  display: inline-block;
}

.page-form > button {
  background: #0029ff;
  border-radius: 8px;
  line-height: 56px;
  height: 56px;
  width: 100%;
  border: 0;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  font-family: GT Walsheim Pro Bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-1px);
}

.page-form > button > svg {
  height: 20px;
  width: 40px;
  display: none;
}

.page-form > button:disabled > svg {
  display: inline;
}

.floating-div {
  display: inline-flex;
  align-items: flex-start;
  padding: 22px 18px;
  background: rgba(251, 251, 251, 0.06);
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  position: relative;
  margin-top: 48px;
  font-size: 12px;
}

.floating-div > svg {
  width: 15px;
  margin-right: 7px;
}

.toast {
  width: 90%;
  max-width: 480px;
  background: #ffffff;
  border-radius: 0 11.8px 11.8px 0;
  position: fixed;
  height: 60px;
  top: 24px;
  right: 24px;
  transform: translateX(200%);
  color: #000;
  display: none;
  align-items: center;
}

.toast-success {
  display: flex;
  border-left: 3px solid #15a336;
  animation: toast-enter 0.3s ease-in forwards;
}

.toast-warning {
  display: flex;
  border-left: 3px solid #e7b52b;
  animation: toast-enter 0.5s ease-in forwards;
}

.toast-warning > button > svg path {
  fill: #e7b52b;
}

.toast-warning > .circle {
  background-color: rgba(231, 181, 43, 0.1);
  display: none;
}

.toast-message {
  margin: 0 40px 0 18px;
}

.circle {
  height: 32px;
  width: 32px;
  background-color: rgba(21, 163, 54, 0.06);
  border-radius: 50%;
  z-index: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 13px;
}

.circle > svg {
  z-index: 1;
  height: 14px;
  width: 14px;
}

.toast-close {
  display: inline-block;
  background-color: transparent;
  border: none;
  position: absolute;
  right: 24px;
  height: 20px;
  width: 20px;
}

@keyframes toast-enter {
  0% {
    transform: translateX(110%);
  }
  70% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(0);
  }
}

.dialog {
  display: none;
}

.dialog.open {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.dialog-success.open {
  display: flex;
}

.dialog-success {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 500px;
  background: #000624;
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 48px;
  display: none;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.dialog-success > h3 {
  font-size: 30px;
  font-family: GT Walsheim Pro Bold;
  margin-bottom: 15px;
}

.rocket {
  font-size: 48px;
  margin-bottom: 24px;
}

.share-box {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  width: 100%;
  max-width: 220px;
  margin: 40px auto 0;
}

.share-button {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  color: #fff;
  box-shadow: 0px 10px 15px -2px rgba(0, 41, 255, 0.15);
  border-radius: 8px;
  transition: width 0.35s linear;
}

.share-button.clicked {
  width: 45%;
}

.share-social {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s linear;
}

.share-link {
  display: inline-flex;
  height: 58px;
  width: 58px;
  background-color: var(--primary);
  color: #fff;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 10px 15px -2px rgba(0, 41, 255, 0.15);
  border-radius: 8px;
}

.share-link > svg {
  width: 20px;
  height: 20px;
}

.dialog-content.open {
  display: block;
}

.dialog-content {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 90%;
  background: #000624;
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
  border-radius: 16px 16px 0 0;
  padding: 30px 24px;
}

.dialog > .back-drop {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(30, 31, 38, 0.3);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

.dialog-content > svg {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
}

.dialog-content > header {
  display: flex;
  justify-content: space-between;
}

.dialog-content > header > div {
  display: none;
}

.dialog-content > article {
  background-color: rgba(255, 255, 255, 0.06);
  padding: 24px;
  margin-top: 24px;
  border-radius: 16px;
  overflow: scroll;
  height: 100%;
  padding-bottom: 50px;
}

article p {
  margin-top: 16px;
  font-weight: normal;
}
article > div:not(:first-of-type) {
  margin-top: 24px;
}

.dialog-content-header {
  font-size: 1.25rem;
  font-family: GT Walsheim Pro Bold;
}

article p:first-child {
  margin-top: 0;
}

.key {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  font-size: 12px;
  padding: 2px 10px;
  display: inline-block;
  margin-left: 5px;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.auth-links {
  display: none;
}

@media (min-width: 700px) {
  .nav {
    max-width: 86%;
    margin: 0 auto;
  }

  .auth-links {
    display: flex;
    list-style-type: none;
    gap: 32px;
    align-items: center;
  }

  .auth-links a {
    text-decoration: none;
  }

  .signup-btn {
    background: #0029ff;
    border-radius: 8px;
    width: 126px;
    display: inline-block;
    text-align: center;
    padding: 10px 0;
  }

  .top-section {
    max-width: 75%;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 150px;
  }

  .content-box > h1 {
    animation: animate-heading 0.3s ease-in forwards;
  }

  .content-box > p {
    font-size: 18px;
    margin: 24px auto;
  }

  .dashboard-image {
    min-height: 500px;
  }

  .cta-btn {
    width: 200px;
    margin-bottom: 80px;
  }

  .logo-box img {
    height: 50px;
    width: auto;
  }

  .dialog-success {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
    max-width: 480px;
    background: #000624;
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding: 48px;
    display: none;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .share-box {
    max-width: none;
  }

  .share-button {
    display: block;
  }

  .share-social {
    opacity: 0;
    visibility: hidden;
  }

  .share-social {
    width: 52%;
  }

  .row-flex {
    display: flex;
    flex-direction: row;
  }

  .row-flex > div {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .reverse {
    flex-direction: row-reverse;
  }
}

@keyframes animate-heading {
  from {
    color: transparent;
    filter: blur(4px);
    text-shadow: 0 0 32px rgba(0, 0, 0, 0.5);
    transform: scale3d(1.05, 1.1, 1.05);
  }
  to {
    text-shadow: none;
    color: #fff;
    transform: scale3d(1, 1, 1);
  }
}
