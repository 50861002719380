.access {
  background: rgba(255, 255, 255, 0.05);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
  border-radius: 48px 48px 0px 0px;
  padding: 32px 24px;
  margin: 100px var(--padding) 80px;
  position: relative;
}

.access-content > p {
  margin: 20px 0;
}

.access-content > h3 {
  margin: 0;
}

.access-button {
  width: 100%;
}

.phone-container {
  display: none;
}

@media (min-width: 700px) {
  .access {
    padding: 80px 120px 120px;
    margin: 150px auto 80px;
    position: relative;
    max-width: 75%;
  }

  .access-content {
    width: 50%;
  }

  .access-content > p {
    margin: 20px 0;
  }

  .access-button {
    width: 180px;
  }

  .phone-container {
    position: absolute;
    top: -120px;
    right: 65px;
    display: block;
  }

  .phone-container {
    transform: translateY(12%);
  }
}
